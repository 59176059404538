<template>
    <div id="codes-collector">
        <van-search
            v-model="keywords"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
        >
            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
        <van-pull-refresh class="goods-list-container" v-model="refreshing" @refresh="onRefresh()">
            <van-list
                v-if="!isEmpty"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad()">
                <div v-for="(item,index) in goodsList" :key="index" class="goods-item-box">
                    <img class="cover" v-lazy="item['imgurl']" alt=""/>
                    <div class="info-box">
                        <span class="title">{{item.product_name}}</span>
                        <div class="basic-info-box">
                            <div class="basic-info">
                                <span>规格：{{item.specifications}}</span>
                                <span class="code">条码：{{item.barcode?item.barcode:'-'}}</span>
                            </div>
                            <div class="scan-code" @click="scanCode(index)">扫码</div>
                        </div>
                    </div>
                </div>
            </van-list>
            <van-empty v-else
                       :image="require(`@images/no-goods.png`)"
                       description="没有找到相关商品信息"/>
        </van-pull-refresh>
        <van-dialog class="barcode-dialog"
                    v-model="show" title="确认扫码结果"
                    show-cancel-button
                    confirm-button-text="录入"
                    cancel-button-text="取消"
                    @confirm="submitCode(currentIndex)">
            <label>
                <input class="barcode" v-model="currentBarCode" type="text" placeholder="请填写条形码"/>
            </label>
        </van-dialog>
        <img class="btn-shop-selector" src="./../assets/images/btn-shop.png" alt="" @click="toShopSelector"/>
    </div>
</template>

<script>
    import {codesCollectorGoodsList, submitBarCode} from "../api/requist";
    import {Toast} from "vant";
    import wxShare from "../common/js/wxShare";
    import {eventBus} from "../common/js/bus";
    import router from "../router";

    export default {
        name: "codesCollector",
        data() {
            return {
                keywords: '',
                refreshing: false,
                loading: false,
                finished: false,
                isEmpty: false,
                pageCurrent: 0,
                pageSize: 15,
                goodsList: [],
                currentBarCode: '',
                currentIndex: -1,
                show: false
            }
        },
        created() {
            eventBus.$on('scanSuccess', (result, index) => {
                this.scanCallback(result, index);
            });
        },
        methods: {
            // 前往店铺选择器
            toShopSelector: function () {
                router.push({
                    path: '/shopSelector'
                })
            },

            // 扫码
            scanCode: function (index) {
                Toast.loading({
                    message: '正在开启扫码器',
                    forbidClick: true,
                });
                this.currentBarCode = '';
                this.currentIndex = -1;
                wxShare.onScan(index);
            },

            // 扫码结果回调
            scanCallback: function (res, index) {
                Toast.clear();
                let split = res.split(',');
                this.currentBarCode = split[1];
                this.currentIndex = index;
                this.show = true;
            },

            // 提交扫码结果
            submitCode: function (index) {
                Toast.loading({
                    message: '正在提交...',
                    forbidClick: true,
                });
                let formData = new FormData();
                formData.append('atlas_id', this.goodsList[index].id);
                formData.append('barcode', this.currentBarCode);
                submitBarCode(formData).then((res) => {
                    if (res.code === 200) {
                        Toast.success('提交成功');
                        this.goodsList[index].barcode = this.currentBarCode;
                        this.currentBarCode = '';
                        this.currentIndex = -1;
                    } else {
                        Toast.fail('提交失败');
                    }
                });
            },

            // 获取商品列表
            getGoods: function () {
                let _that = this;
                let _pageCurrent = this.pageCurrent;
                let formData = new FormData();
                formData.append('product_name', this.keywords ? this.keywords : '');
                formData.append('pageCurrent', _pageCurrent + 1 + '');
                formData.append('pageSize', this.pageSize);
                codesCollectorGoodsList(formData).then((res) => {
                    let _code = res.code;
                    if (_code === 200) {
                        let goods = res.data['ProductAtlasList'];
                        if (_pageCurrent === 0) {
                            _that.goodsList = [...goods];
                        } else {
                            _that.goodsList = [..._that.goodsList, ...goods];
                        }
                        if (goods.length < _that.pageSize) {
                            _that.finished = true;
                        }
                        _that.refreshing = false;
                        _that.loading = false;
                        _that.pageCurrent++;
                    } else if (_code === 202) {
                        // 当暂无对应类型订单数据时，显示缺省信息
                        this.refreshing = false;
                        this.loading = false;
                        this.finished = true;
                        this.isEmpty = true;
                    } else {
                        return Toast(res.message);
                    }
                })
            },

            // 刷新
            onRefresh: function () {
                this.finished = false;
                this.pageCurrent = 0;
                this.getGoods();
            },

            // 上拉加载更多
            onLoad: function () {
                this.getGoods();
            },

            // 搜索
            onSearch: function () {
                this.finished = false;
                this.pageCurrent = 0;
                this.getGoods();
            }
        }
    }
</script>

<style lang="scss">
    #codes-collector {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .btn-shop-selector {
            width: 45px;
            height: 45px;
            position: fixed;
            bottom: 40px;
            right: 24px;
            border-radius: 50%;
            box-shadow: 0 3px 5px rgba(48, 49, 51, 0.16);
        }

        .van-search {
            flex: 0;
            padding-left: 16px;

            .van-search__action {
                padding: 0 16px;
            }
        }

        .goods-list-container {
            flex: 1;
            overflow-y: scroll;

            .goods-item-box {
                display: flex;
                flex-direction: row;
                padding: 24px 16px 0 16px;

                .cover {
                    flex: 0 80px;
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                }

                .info-box {
                    margin-left: 8px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        max-height: 40px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #303133;
                    }

                    .basic-info-box {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;

                        .basic-info {
                            font-size: 14px;
                            color: #909399;
                            display: flex;
                            flex-direction: column;

                            .code {
                                margin-top: 8px;
                            }
                        }

                        .scan-code {
                            font-size: 12px;
                            padding: 6px 22px;
                            color: white;
                            background-color: #FF6737;
                            border-radius: 50px;
                            box-shadow: 0 2px 4px rgba(255, 103, 55, 0.16);
                        }
                    }
                }
            }

            .van-empty {
                padding-top: 30%;

                .van-empty__image {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .barcode-dialog {
            text-align: center;

            .van-dialog__content {
                padding: 0 16px;

                .barcode {
                    width: 100%;
                    font-size: 14px;
                    line-height: 18px;
                    margin: 24px 0;
                    padding: 9px 12px;
                    box-sizing: border-box;
                    color: #303133;
                    border-radius: 4px;
                    background-color: #f5f7fa;
                    border: solid 1px #f2f6fc;
                }
            }
        }
    }
</style>
