import { render, staticRenderFns } from "./codesCollector.vue?vue&type=template&id=ed3b6cb6"
import script from "./codesCollector.vue?vue&type=script&lang=js"
export * from "./codesCollector.vue?vue&type=script&lang=js"
import style0 from "./codesCollector.vue?vue&type=style&index=0&id=ed3b6cb6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports